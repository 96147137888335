import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from "styled-components";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";

    

export default function AboutUs() {
    return (
        <Root>

            <div class="row p-3">
                <div class="container-fluid bg-black text-white">
                    <div class="row d-flex align-items-center justify-content-between py-5">
                   <div class="col-12 text-center d-flex flex-column justify-content-center py-5 ">
                            <span class="d-block text-xl text-md-2xl font-weight-light mb-3 mb-md-0">About Us</span>
                           
                            <span class="display-4 font-weight-light">Making the World Shine Brighter</span>
                        </div>
                    </div>
                </div>

            </div>
            <div className="container p-4  py-3 " id="about_us">
                <Section1 />
                <Section2 />
                <Section3 />

            </div>
            <Section4 />
        </Root>
    );
}

const Root = styled.section`

  .container{
width:90%;

}

@media (max-width: 567px) {
    .container {
      width: 100%;
    }
  }

  @media (min-width: 567px) and (max-width: 992px) {
    .container {
      width: 100%;
    }
  }
  
 `;
