import { EXCHANGE_URLS_WEDDING } from "../URLS";
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import axios from "axios";
import aeroplane from "../../Images/aeroplane.png";
import badgess from "../../Images/badgess.png";
import moneyinhand from "../../Images/moneyinhand.png";
import certifiedd from "../../Images/certifiedd.png";
import Drawer from "react-modern-drawer";
// import ring from "../../Images/ringwithdiamond.png";
import wid from "../../Images/svgviewer-png-output.png";
import met from "../../Images/svgviewer-png-output (3).png";
import circle from "../../Images/Screenshot from 2024-07-25 17-29-15.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useLoading } from "../LoadingContext";
import ww from "../../Images/ww.webp";
import one_four_k_white_gold from "../../Images/fourone.png";
import one_four_k_yellow_gold from "../../Images/fourtwo.png";
import one_four_k_pink_gold from "../../Images/fourthree.png";
import one_eight_k_white_gold from "../../Images/eightone.png";
import one_eight_k_yellow_gold from "../../Images/eighttwo.png";
import one_eight_k_red from "../../Images/eightthree.png";
import Platinum from "../../Images/pt.png";
import noimg from "../../Images/eligantPacking.png";
import solid from "../../Images/SolidHer.png";
import pave from "../../Images/PaveHer.png";
import classic from "../../Images/classic.png";
import chevron from "../../Images/chevron.png";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import fourmm from "../../Images/fourmm.png";
import fivemm from "../../Images/fivemm.png";
import sixmm from "../../Images/sixmm.png";

import diamond_half_way from "../../Images/diamondhalfway.png";
import diamond_all_around from "../../Images/diamondallaround.png";

import ringwithdiamond from "../../Images/ringwithdiamond.png";
import ship from "../../Images/ship.png";
import ret from "../../Images/ret.png";

import { useDispatch, useSelector } from "react-redux";

const materialList = [
  {
    value: "14k White Gold",
    name: "White Gold",
    imgUrl: one_four_k_white_gold,
  },
  {
    value: "14k Yellow Gold",
    name: "Yellow Gold",
    imgUrl: one_four_k_yellow_gold,
  },
  { value: "14k Rose Gold", name: "Rose Gold", imgUrl: one_four_k_pink_gold },
  {
    value: "18k White Gold",
    name: "White Gold",
    imgUrl: one_eight_k_white_gold,
  },
  {
    value: "18k Yellow Gold",
    name: "Yellow Gold",
    imgUrl: one_eight_k_yellow_gold,
  },
  { value: "18k Rose Gold", name: "Rose Gold", imgUrl: one_eight_k_red },
  { value: "Platinum", name: "Platinum", imgUrl: Platinum },
];

const settingList = [
  { value: "Diamonds Half Way", imgUrl: diamond_half_way },
  { value: "Diamonds All Around", imgUrl: diamond_all_around },
];

const styleList = [
  { value: "Marquise", imgUrl: solid },
  { value: "Round", imgUrl: pave },
  { value: "Round & Marquise", imgUrl: classic },
  { value: "Solid", imgUrl: solid },
  { value: "Pave", imgUrl: pave },
  { value: "Classic", imgUrl: classic },
  { value: "Chevron", imgUrl: chevron },
];

const widthList = [
  { value: "3MM", imgUrl: fourmm },
  { value: "4MM", imgUrl: fourmm },
  { value: "5MM", imgUrl: fivemm },
  { value: "6MM", imgUrl: sixmm },
  // { value: "7MM", imgUrl: sixmm },
];

export default function MoreForHerBand() {
  const [availableMaterials, setAvailableMaterials] = useState([]);
  const [availableSettings, setAvailableSettings] = useState([]);
  const [availableStyles, setAvailableStyles] = useState([]);
  const [availableWidths, setAvailableWidths] = useState([]);
  const dispatch = useDispatch();
  const [unique, setUnique] = useState(null);
  const [material, setMaterial] = useState(materialList[0]?.value || "");
  const [setting, setSetting] = useState( "");
  const [styles, setStyles] = useState("");
  const [selectedStyle, setSelectedStyle] = useState("");
  const [selectedSetting, setSelectedSetting] = useState("");
  const [selectedWidth, setSelectedWidth] = useState("");
  const [selectedMaterial, setSelectedMaterial] = useState(materialList[0]?.value || "");
  const [widths, setWidths] = useState([]);
  const { setLoading } = useLoading();
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  // Access the product and productId from the passed state and query string
  const product = location.state?.product;
  const productId = location.state?.product.id;

  console.log("Product:", product);
  console.log("Product ID:", productId);

  const fetchAndSetVariantForHer = (productId) => async (dispatch) => {
    try {
      const response = await axios.get(
        `https://api-diamond.phanomprofessionals.com/api/rings/enternityvariation?productId=${productId}`
        //  `localhost:3200/api/rings/enternityvariation?productId=${productId}`
      );
      const { data } = response.data;

      const variants = data.variants;
      const materials = new Set();
      const settings = new Set();
      const styles = new Set();
      const widths = new Set();

      variants.edges.forEach((variant) => {
        variant.node.selectedOptions.forEach((option) => {
          if (option.name === "material") {
            materials.add(option.value);
          }
          if (option.name === "settingtype") {
            settings.add(option.value);
          }
          if (option.name === "width") {
            widths.add(option.value);
          }
          if (option.name === "style") {
            styles.add(option.value);
          }
        });
      });

      setAvailableMaterials([...materials]);
      setAvailableSettings([...settings]);
      setAvailableStyles([...styles]);
      setAvailableWidths([...widths]);
    } catch (error) {
      console.error("Error fetching variant for her:", error);
    }
  };

  const variantForHer = useSelector((state) => state.users.variantForHer);

  useEffect(() => {
    if (productId) {
      dispatch(fetchAndSetVariantForHer(productId));
    }
    if (variantForHer) {
      const materials = [
        ...new Set(variantForHer?.materials?.map((option) => option.value)),
      ];
      const settings = [
        ...new Set(variantForHer?.settings?.map((option) => option.value)),
      ];
      const styles = [
        ...new Set(variantForHer?.styles?.map((option) => option.value)),
      ];
      const widths = [
        ...new Set(variantForHer?.widths?.map((option) => option.value)),
      ];

      setMaterial(materials);
      setSetting(settings);
      setStyles(styles);
      setWidths(widths);
    }
  }, [variantForHer, productId, dispatch]);

  useEffect(() => {
    if (productId) {
      fetchUniqueData(
        productId,
        selectedMaterial,
        selectedWidth,
        selectedSetting,
        selectedStyle
      );
    }
  }, [selectedMaterial, selectedSetting, selectedStyle,productId, selectedWidth]);

  const fetchUniqueData = async (productId) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${EXCHANGE_URLS_WEDDING}/wddingfilter?productId=${productId}&material=${selectedMaterial}&settingtype=${selectedSetting}&width=${selectedWidth}&style=${selectedStyle}`
      );
      if (response.status === 200) {
        setUnique(response.data.data[0]); // Assuming the product data is in response.data.data[0]
        console.log("PredefinedData:", response.data.data[0]);
        const material = [];
        const setting = [];
        const styles = [];     
        const widths = [];

        response.data.data[0]?.selectedOptions?.forEach((option) => {
          if (option.name === "material" && !material.includes(option.value)) {
            material.push(option.value);
          }
          if (
            option.name === "settingtype" &&
            !setting.includes(option.value)
          ) {
            setting.push(option.value);
          }
          if (option.name === "style" && !styles.includes(option.value)) {
            styles.push(option.value);
          }
          if (option.name === "width" && !widths.includes(option.value)) {
            widths.push(option.value);
          }
        });

        if (material.length > 0) {
          setMaterial(material);
        }
        if (setting.length > 0) {
          setSetting(setting);
        }
        if (styles.length > 0) {
          setStyles(styles);
        }
        if (widths.length > 0) {
          setWidths(widths);
        }
      }
    } catch (error) {
      console.error("Error fetching collections:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCheckout = () => {
    navigate("/checkout-wedding-bands", {
      state: {
        uniqueher: unique,
        weddinghermaterial: material,
        weddinghersetting: setting,
        weddingherstyles: styles,
        weddingherwidths: widths,
        productImageher: unique.image,
        productTitleher: unique.title,
        productPriceher: unique.price,
      },
    });
  };
      
  const handleSettingClick = (shapeName) => {
    setSelectedSetting(shapeName);
  };

  const handleStyleClick = (shapeName) => {
    setSelectedStyle(shapeName);
  };

  const handleWidthClick = (shapeName) => {
    setSelectedWidth(shapeName);
  };

  const handleMaterialClick = (selectedMaterial) => {
    setSelectedMaterial(selectedMaterial);
  };

  const imageUrl = unique?.image?.originalSrc || "No Product Variant Available";

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };
  return (
    <Root>
      <div className="main_wrapper">
        <div className="image_div">
          <ImageContainer>
            {imageUrl ? (
              <img src={imageUrl} title="Product Image" alt="Product" />
            ) : (
              <img src={noimg} alt="img not available" />
            )}
          </ImageContainer>
        </div>
        <div className="des_div">
          <div className="title">
            <h2>{unique?.title}</h2>
            <h4>${unique?.price}</h4>
            <p>{unique?.description || "No Description About Product"}</p>
          </div>
          {availableMaterials.length > 0 && (
            <div className="ring_types mt-4">
              <h4>Material: {selectedMaterial}</h4>
              <div>
                {materialList.map((materialItem) =>
                  availableMaterials.includes(materialItem.value) ? (
                    <button
                      key={materialItem.value}
                      className={`btn_shapes ${
                        selectedMaterial === materialItem.value
                          ? "selected"
                          : ""
                      }`}
                      onClick={() => handleMaterialClick(materialItem.value)}
                    >
                      <img
                        className="img color_div"
                        src={materialItem.imgUrl}
                        alt={materialItem.value}
                      />
                      <p>{materialItem.name}</p>
                    </button>
                  ) : null
                )}
              </div>
            </div>
          )}

          {availableSettings.length > 0 && (
            <div className="ring_types mt-4">
              <h4>Setting Type: {selectedSetting}</h4>
              <div>
                {settingList.map((settingItem) =>
                  availableSettings.includes(settingItem.value) ? (
                    <button
                      key={settingItem.value}
                      className={`btn_shapes ${
                        selectedSetting === settingItem.value ? "selected" : ""
                      }`}
                      onClick={() => handleSettingClick(settingItem.value)}
                    >
                      <img
                        className="img color_div"
                        src={settingItem.imgUrl}
                        alt={settingItem.value}
                      />
                      <p>{settingItem.value}</p>
                    </button>
                  ) : null
                )}
              </div>
            </div>
          )}

          {availableStyles.length > 0 && (
            <div className="ring_types mt-4">
              <h4>Style: {selectedStyle}</h4>
              <div>
                {styleList.map((styleItem) =>
                  availableStyles.includes(styleItem.value) ? (
                    <button
                      key={styleItem.value}
                      className={`btn_shapes ${
                        selectedStyle === styleItem.value ? "selected" : ""
                      }`}
                      onClick={() => handleStyleClick(styleItem.value)}
                    >
                      <img
                        className="img color_div"
                        src={styleItem.imgUrl}
                        alt={styleItem.value}
                      />
                      <p>{styleItem.value}</p>
                    </button>
                  ) : null
                )}
              </div>
            </div>
          )}

          {availableWidths.length > 0 && (
            <div className="ring_types mt-4">
              <h4>Width: {selectedWidth}</h4>
              <div>
                {widthList.map((widthItem) =>
                  availableWidths.includes(widthItem.value) ? (
                    <button
                      key={widthItem.value}
                      className={`btn_shapes ${
                        selectedWidth === widthItem.value ? "selected" : ""
                      }`}
                      onClick={() => handleWidthClick(widthItem.value)}
                    >
                      <img
                        className="img color_div"
                        src={widthItem.imgUrl}
                        alt={widthItem.value}
                      />
                      <p>{widthItem.value}</p>
                    </button>
                  ) : null
                )}
              </div>
            </div>
          )}

          <div className="ring_size">
            <select>
              <option value="">Select Ring Size</option>
              <option>3</option>
              <option>3.5</option>
              <option>4</option>
              <option>4.5</option>
              <option>5</option>
            </select>
          </div>

          <div className="total_price">
            <h5>Total Price</h5>
            <h2>${unique?.price}</h2>
            <p>Ships in 2-3 weeks</p>
          </div>

          <div className="product_btn">
            <button
              className="btn"
              onClick={() => {
                toggleDrawer();
              }}
            >
              {material ? "Add to Cart" : "Select Ring Size"}
            </button>

            <Drawer
              open={isOpen}
              onClose={toggleDrawer}
              direction="right"
              className="custom-drawer"
              size="300px"
            >
              <div className="cart_heading">
                <h2>My Shopping Bag</h2>
              </div>

              <div className="prod_main_div">
                <div className="prod_div">
                  <div className="prod">
                    <div
                      className="bg-img"
                      style={{
                        backgroundImage: `url(${imageUrl})`,
                        backgroundSize: "100%",
                        backgroundRepeat: "center",
                      }}
                    >
                      <div className="dia_img">
                        <img src={imageUrl} alt="img" />
                      </div>
                    </div>

                    <div className="prod_name">
                      <h3>{unique?.title}</h3>
                    </div>

                    <div className="prod_spec">
                      <div className="icon_content">
                        <img src={imageUrl} alt="img" />
                        <div className="content_head">
                          <h4>{unique?.title}</h4>
                          {/* <p>14k White Gold </p> */}
                        </div>
                      </div>
                      <div className="prod_price">
                        <h4>${unique?.price}</h4>
                      </div>
                    </div>

                    <div className="prod_spec">
                      <div className="icon_content">
                        <div className="d-flex gap-3">
                          <h4>Material:</h4>
                          <h5 style={{ fontWeight: "400px", fontSize: "14px" }}>
                            {" "}
                            {selectedMaterial || "Free"}
                          </h5>
                        </div>
                      </div>

                      <div className="icon_content">
                        <div className="d-flex gap-3">
                          <h4>Setting Type:</h4>
                          <h5 style={{ fontWeight: "400px", fontSize: "14px" }}>
                            {" "}
                            {selectedSetting || "Free"}{" "}
                          </h5>
                        </div>
                      </div>

                      <div className="icon_content">
                        <div className="d-flex gap-3">
                          <h4>Style: </h4>
                          <h5 style={{ fontWeight: "400px", fontSize: "14px" }}>
                            {" "}
                            {selectedStyle || "Free"}{" "}
                          </h5>
                        </div>
                      </div>

                      <div className="icon_content">
                        <div className="d-flex gap-3">
                          <h4>Width:</h4>
                          <h5 style={{ fontWeight: "400px", fontSize: "14px" }}>
                            {" "}
                            {selectedWidth || "Free"}{" "}
                          </h5>
                        </div>
                      </div>
                    </div>

                    {/* <div className="prod_spec">
                      <div className="icon_content">
                        <img src={diamondd} alt="img" />
                        <div className="content_head">
                          <h4>Round </h4>
                          <p>0.5 Carat J VS1</p>
                        </div>
                      </div>
                      <div className="prod_price">
                        <h4>$713</h4>
                      </div>
                    </div>  */}

                    {/* <div className="price_div">
                      <p>
                        Total: <span style={{ color: "#000000" }}>$1,413</span>
                      </p>
                      <div className="delete_icon">
                        <img src={deleteicon} alt="img" />
                      </div>
                    </div> */}
                  </div>
                </div>
                {/* <div className="prod_div">
                  <div className="prod">
                    <div className="bg-img">
                      <div className="dia_img">
                        <img src={dia} alt="img" />
                      </div>
                    </div>

                    <div className="prod_name">
                      <h3>
                        The Ashley with a 0.5 Carat J VS1 Round Natural Diamond
                      </h3>
                    </div>

                    <div className="prod_spec">
                      <div className="icon_content">
                        <img src={ring} alt="img" />
                        <div className="content_head">
                          <h4>The Ashley</h4>
                          <p>14k White Gold</p>
                        </div>
                      </div>
                      <div className="prod_price">
                        <h4>$700</h4>
                      </div>
                    </div>

                    <div className="prod_spec">
                      <div className="icon_content">
                        <img src={diamondd} alt="img" />
                        <div className="content_head">
                          <h4>Round </h4>
                          <p>0.5 Carat J VS1</p>
                        </div>
                      </div>
                      <div className="prod_price">
                        <h4>$713</h4>
                      </div>
                    </div>

                    <div className="price_div">
                      <p>
                        Total: <span style={{ color: "#000000" }}>$1,413</span>
                      </p>
                      <div className="delete_icon">
                        <img src={deleteicon} alt="img"/>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>

              <div className="total_price_div">
                <p>Total:</p>
                <h4>${unique?.price}</h4>
              </div>

              <div className="but_div">
                <button onClick={handleCheckout}>Checkout Now</button>
              </div>
            </Drawer>
          </div>

          <div className="policy">
            <div className="policy_type">
              <img src={aeroplane} alt="aeroplane_images" />
              <p>
                Overnight <br />
                Shipping
              </p>
            </div>

            <div className="policy_type">
              <img
                src={badgess}
                alt="badgess_images"
                style={{ width: "50px", height: "50px" }}
              />
              <p>
                Lifetime <br />
                Warranty
              </p>
            </div>

            <div className="policy_type">
              <img src={moneyinhand} alt="moneyinhand_images" />
              <p>
                30 Days <br />
                Free Return
              </p>
            </div>

            <div className="policy_type">
              <img src={certifiedd} alt="certifiedd_images" />
              <p>
                Certificate
                <br />& Appraisal
              </p>
            </div>
          </div>

          <div className="setting_detail">
            <div className="setting_main_div">
              <h4 className="seting_content">Setting Details</h4>
              <div className="subfirst_detail">
                <div className="profile_div">
                  <div className="profile_cont">
                    <img src={wid} alt="pinkimg" />
                    <p>WIDTH</p>
                  </div>

                  <h4>1.8mm</h4>

                  <div className="img_div" style={{ visibility: "hidden" }}>
                    <img src={circle} alt="img" />
                  </div>
                  <p className="para">Measured at the base of the ring</p>
                </div>
              </div>

              <div className="subsecond_detail">
                <div className="profile_div">
                  <div className="profile_cont">
                    <img src={met} alt="pinkimg" />
                    <p>METAL</p>
                  </div>

                  <h4>14k White Gold</h4>

                  <div className="img_div">
                    <img src={circle} alt="img" />

                    <div className="quality_content">
                      <div className="color_content">
                        <div
                          className="color_box"
                          style={{ backgroundColor: "#F0E68C" }}
                        ></div>
                        <h5>58.5% Gold</h5>
                      </div>
                      <div className="color_content">
                        <div
                          className="color_box"
                          style={{ backgroundColor: "#BAC4C8" }}
                        ></div>
                        <h5>8.7% Zinc</h5>
                      </div>
                    </div>

                    <div className="quality_content">
                      <div className="color_content">
                        <div
                          className="color_box"
                          style={{ backgroundColor: "#B87333" }}
                        ></div>
                        <h5>25.4% Copper</h5>
                      </div>
                      <div className="color_content">
                        <div
                          className="color_box"
                          style={{ backgroundColor: "#BDBAAE" }}
                        ></div>
                        <h5>7.3% Nickel</h5>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p className="para">
                      The secret sauce that makes this piece.
                    </p>
                    <p className="para">
                      *All white gold pieces are Rhodium plated
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="acc_div">
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <div className="heading">
                  <img
                    src={ringwithdiamond}
                    alt="img"
                    style={{ width: "25px" }}
                  />

                  <h5>Wedding Band Details</h5>
                </div>
              </AccordionSummary>

              <AccordionDetails>
                <div className="heading">
                  <h5>Details</h5>
                </div>
                <table>
                  <tr>
                    <td>SKU</td>
                    <td>316Q-WB-4-HA-WG-14</td>
                  </tr>
                  <tr>
                    <td>Width</td>
                    <td>4.0mm</td>
                  </tr>
                  <tr>
                    <td>Material</td>
                    <td>14k White Gold</td>
                  </tr>

                  <tr>
                    <td>Type</td>
                    <td>Hammered</td>
                  </tr>

                  <tr>
                    <td>Width</td>
                    <td>4MM</td>
                  </tr>

                  <tr>
                    <td>Cut</td>
                    <td>Excellent</td>
                  </tr>
                </table>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <div className="heading">
                  <img src={ship} alt="img" />

                  <h5>Shipping</h5>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <p className="description">
                  This item is made to order and takes 2-3 weeks to craft. We
                  ship FedEx Priority Overnight, signature required and fully
                  insured.
                </p>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <div className="heading">
                  <img
                    src={ret}
                    alt="img"
                    style={{ width: "20px", height: "20px" }}
                  />

                  <h5>Return Policy</h5>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <p className="description">
                  Received an item you don't like? We are proud to offer free
                  returns within 30 days from receiving your item. Contact our
                  support team to issue a return.
                </p>
              </AccordionDetails>
            </Accordion>
          </div>

          <div className="appointment">
            <h5>Virtual Appointment</h5>
            <p>
              <strong>See jewelry</strong> up close with a personal appointment.
              Explore engagement rings, diamonds, and fine jewelry in person
              through your device.
            </p>
          </div>
        </div>
      </div>
    </Root>
  );
}

const Root = styled.section`
  padding: 20px;
  margin: 20px 0px;
  position: relative;

  .custom-drawer {
    z-index: 11111111 !important;
  }

  .cart_heading {
    padding: 16px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
      0 1px 2px -1px rgba(0, 0, 0, 0.1);

    h2 {
      font-size: 16px;
      color: #000000;
      font-weight: 400;
    }
  }
  .prod_main_div {
    width: 100%;
    max-height: 420px;
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;

    .prod_div {
      padding: 10px 16px;
      .prod {
        padding: 12px;
        background-color: #f7f7f7;
        border-radius: 1.25rem;
        display: flex;
        flex-direction: column;
        gap: 7px;
        .bg-img {
          height: 180px;
          /* background-image:url(${ww}); */
          background-size: 100%;
          background-repeat: no-repeat;
          .dia_img {
            display: flex;
            align-items: flex-end;
            height: 100%;
            width: 100%;
            justify-content: flex-end;

            img {
              width: 20%;
              height: 20%;
              object-fit: contain;
            }
          }
        }
        .prod_name {
          h3 {
            font-size: 13px;
            color: #000000;
            font-weight: 400;
          }
        }
        .prod_spec {
          display: flex;
          justify-content: space-between;
          padding-bottom: 10px;
          border-bottom: 1px solid #ededed;
          .icon_content {
            display: flex;
            align-items: center;
            img {
              width: 40px;
              height: 40px;
              mix-blend-mode: multiply;
            }
            .content_head {
              display: flex;
              flex-direction: column;
              h4 {
                font-size: 12px;
                color: #000000;
                margin-bottom: 0;
                font-weight: 500;
              }
              p {
                font-size: 12px;
                color: #808080;
                margin-bottom: 0;
              }
            }
          }
          .prod_price {
            h4 {
              font-weight: 500;
              font-size: 14px;
              margin-bottom: 0;
            }
          }
        }
        .price_div {
          display: flex;
          justify-content: space-between;
          p {
            font-size: 16px;
            color: rgba(102, 102, 102);
            font-weight: 500;
          }

          .delete_icon {
            img {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .element-with-scroll::-webkit-scrollbar {
    display: none;
  }

  .total_price_div {
    padding: 0px 16px;
    display: flex;
    justify-content: space-between;

    p {
      font-size: 18px;
      color: #666666;
    }

    h4 {
      font-weight: 500;
      font-size: 18px;
      color: #000000;
    }
  }
  .but_div {
    padding: 16px;
    margin-bottom: 20px;
    button {
      color: rgba(255, 255, 255);
      font-weight: 600;
      font-size: 1rem;
      text-align: center;
      padding: 1rem 2rem;
      background-color: #000000;
      border: transparent;
      border-radius: 30px;
      width: 100%;
    }
  }

  .main_wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    /* justify-content: space-between; */
    position: relative;

    .image_div {
      /* flex: 1; */
      width: 45%;
      top: 20px;
      border: 1px solid #d3d3d3;
      padding: 20px 20px 0px 20px;
      height: 600px;
      border-radius: 20px;
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 90%;
      }

      button.button {
        background-color: white;
        border: none;
        border-top: 1px solid #d3d3d3;
        border-radius: 0 0 20px 20px;

        svg {
          width: 18px;
          margin-right: 5px;
        }
        span {
          font-size: 13px;
        }
      }
    }

    .des_div {
      /* flex: 1; */
      width: 50%;
      overflow: auto;
      position: relative;
      padding: 10px 0px;
      .ring_size {
        display: flex;
        justify-content: start;
        margin-top: 20px;
        select {
          font-size: 14px;
          font-weight: 400;
          border-radius: 6px;
          background-color: #fff;
          border: 1px solid #e0e0e0;
          padding: 0.75rem 3rem 0.75rem 0.75rem;
          cursor: pointer;
          transition: 0.5s;
          &:hover {
            box-shadow: 0 0 5px #cacaca;
          }
        }
      }
      .title {
        h2 {
          font-size: 23px;
          font-weight: 600;
          line-height: 1.2;
        }

        h4 {
          font-size: 21px;
          color: #666666;
          font-weight: 500;
          line-height: 1.25;
        }
        p {
          font-size: 13px;
          font-weight: 400;
          color: #666666;
        }
      }

      .prod_spec {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        gap: 10px;
        .carattt {
          gap: 10px;
          display: flex;
          flex-wrap: wrap;
        }
        h4 {
          margin-bottom: 0;
          font-size: 14px;
          color: #000000;
          font-weight: 700;
          margin-bottom: 3px;
        }
        .spec {
          display: flex;
          flex-direction: column;
          padding: 10px;
          width: 55px;
          height: 50px;
          justify-content: center;
          border-radius: 11px;
          background: transparent;
          align-items: center;
          cursor: pointer;
          border: 1px solid #bbb9b9;
          &.selected {
            border: 2px solid #000;
          }

          p {
            color: #666666;
            font-size: 11px;
            margin-bottom: 0;
            line-height: 1.2;
          }
        }
      }
      .ring_types {
        display: flex;
        flex-direction: column;

        h4 {
          font-size: 14px;
          color: #000000;
          font-weight: 700;
          margin-bottom: 10px;
        }
        > div {
          display: flex;
          overflow-x: auto;
          .btn_shapes {
            width: 105px !important;
            border: 2px solid transparent;
            background: #fff;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            padding: 0 0;
            font-size: 10px;
            line-height: 25px;
            font-weight: 600;
            p {
              margin-bottom: 0;
            }
            .img {
              width: 40px;
              height: 40px;
            }
            &.selected {
              border: 1px solid black;
              border-radius: 10px;
            }

            &:hover {
              background-color: rgba(247, 247, 247);
            }
          }
        }
      }

      .total_price {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        h5 {
          font-size: 19px;
          font-weight: 500;
          color: rgba(153, 153, 153);
          margin-bottom: 0;
        }
        h2 {
          font-weight: 500;
          font-size: 30px;
          color: #060606;
        }
        p {
          color: rgba(153, 153, 153);
          text-align: center;
          font-size: 14px;
        }
      }
      .product_btn {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 10px;
        .btn {
          background-color: rgba(0, 0, 0);
          color: white;
          font-size: 17px;
          padding: 16px 0;
          font-weight: 600;
          border-radius: 50px;
          border: 1px solid transparent;
        }
      }
    }

    .siz_div {
      width: 35%;
    }
    .setting_main_div {
      display: flex;
      gap: 10px;
      flex-direction: column;
      background-color: #f6f6f9;
      padding: 12px;
      border-radius: 15px;

      .subfirst_detail {
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 50%;

        .profile_div {
          background: #fff;
          border: 1px solid #e0e0e0;
          border-radius: 12px;
          padding: 10px;
          display: flex;
          gap: 8px;
          flex-direction: column;
          .profile_cont {
            display: flex;
            align-items: center;
            p {
              font-size: 12px;
              color: #bbb9b9;
              font-weight: 600;
              text-transform: uppercase;
              margin-bottom: 0;
            }
          }
          h4 {
            color: #000000;
            font-weight: 700;
            font-size: 24px;
            margin-bottom: 0;
          }
          .ring_img {
            width: 70px;
          }
          .img_div {
            display: flex;
            gap: 15px;
            visibility: hidden;
          }

          .para {
            font-size: 12px;
            font-weight: 500;
            color: #666666;
            margin-bottom: 0;
          }
        }
      }

      .subsecond_detail {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .profile_div {
          background: #fff;
          border: 1px solid #e0e0e0;
          border-radius: 12px;
          padding: 10px;
          display: flex;
          gap: 8px;
          flex-direction: column;

          .profile_cont {
            display: flex;
            align-items: center;
            p {
              font-size: 12px;
              color: #bbb9b9;
              font-weight: 600;
              text-transform: uppercase;
              margin-bottom: 0;
            }
          }

          h4 {
            font-size: 24px;
            color: #000000;
            font-weight: 700;
            margin-bottom: 0%;
          }

          .ring_img {
            width: 70px;
          }

          .img_div {
            display: flex;
            gap: 15px;
            .quality_content {
              display: flex;
              flex-direction: column;
              justify-content: center;
              gap: 8px;
              .color_content {
                display: flex;
                gap: 10px;
                align-items: center;
                .color_box {
                  height: 16px;
                  width: 16px;
                  border-radius: 30px;
                }
                h5 {
                  font-size: 11px;
                  font-weight: 500;
                  margin-bottom: 0;
                }
              }
            }
          }

          .para {
            font-size: 12px;
            font-weight: 500;
            color: #666666;
            margin-bottom: 0;
          }
        }
      }
    }

    .policy {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;

      .policy_type {
        flex: 1;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          width: 35px;
          height: 35px;
        }
        p {
          font-size: 14px;
          color: #333;
          margin-top: 10px;
          text-align: center;
          line-height: 16px;
        }
      }
    }

    .seting_content {
      font-size: 16px;
      color: #000000;
      font-weight: 700;
      margin-bottom: 0;
    }

    .setting_detail {
      display: flex;
      flex-direction: column;
      gap: 15px;
      margin-top: 10px;
      h4 {
        font-size: 16px;
        color: #000000;
        font-weight: 700;
      }
      .profile_div {
        background: #fff;
        border: 1px solid #e0e0e0;
        border-radius: 12px;
        padding: 10px;
        display: flex;
        gap: 8px;
        flex-direction: column;
        .profile_cont {
          display: flex;
          gap: 2px;
          align-items: center;
          p {
            font-size: 12px;
            color: #bbb9b9;
            font-weight: 600;
            text-transform: uppercase;
            margin-bottom: 0;
          }
        }
        h4 {
          font-size: 24px;
        }
        .ring_img {
          width: 70px;
        }
        .para {
          font-size: 12px;
          font-weight: 500;
          color: #666666;
          margin-bottom: 0;
        }
      }
    }

    .acc_div {
      margin-top: 20px;
      .heading {
        display: flex;
        gap: 10px;

        svg {
          width: 21px;
          height: 21px;
        }

        h5 {
          font-size: 14px;
          color: rgba(0, 0, 0);
          font-weight: 500;
          margin-top: 3px;
        }
      }

      tr td {
        font-size: 13px;
        color: #777777;
        padding-right: 40px;
      }

      .description {
        font-size: 13px;
        color: #777777;
      }
      .css-1086bdv-MuiPaper-root-MuiAccordion-root {
        box-shadow: unset;
        position: unset;
        border-bottom: 1px solid #d3d3d366;
      }
      .css-1086bdv-MuiPaper-root-MuiAccordion-root::before {
        background-color: #fff;
      }
    }

    .appointment {
      background-color: rgba(247, 247, 247);
      padding: 20px;
      border-radius: 20px;
      margin-top: 25px;

      h5 {
        font-size: 1rem;
        font-weight: 600;
      }
      p {
        font-size: 13px;
        margin-bottom: 0;
      }
      button {
        font-size: 0.875rem;
        letter-spacing: 0;
        line-height: 1.2;
        font-weight: 400;
        text-decoration: underline;
        border: 1px solid transparent;
        margin-top: 10px;
        padding: 0;
        color: #000000;
      }
    }
  }
  .custom-drawer {
    z-index: 11111111 !important;
  }
  .cart_heading {
    padding: 16px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
      0 1px 2px -1px rgba(0, 0, 0, 0.1);

    h2 {
      font-size: 16px;
      color: #000000;
      font-weight: 400;
    }
  }
  .prod_main_div {
    width: 100%;
    max-height: 420px;
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;

    .prod_div {
      padding: 10px 16px;
      .prod {
        padding: 12px;
        background-color: #f7f7f7;
        border-radius: 1.25rem;
        display: flex;
        flex-direction: column;
        gap: 7px;
        .bg-img {
          height: 180px;
          background-image: url(${ww});
          background-size: 100%;
          background-repeat: no-repeat;
          .dia_img {
            display: flex;
            align-items: flex-end;
            height: 100%;
            width: 100%;
            justify-content: flex-end;

            img {
              width: 20%;
              height: 20%;
              object-fit: contain;
            }
          }
        }
        .prod_name {
          h3 {
            font-size: 13px;
            color: #000000;
            font-weight: 400;
          }
        }
        .prod_spec {
          display: flex;
          justify-content: space-between;
          padding-bottom: 10px;
          border-bottom: 1px solid #ededed;
          .icon_content {
            display: flex;
            align-items: center;
            img {
              width: 40px;
              height: 40px;
              mix-blend-mode: multiply;
            }
            .content_head {
              display: flex;
              flex-direction: column;
              h4 {
                font-size: 12px;
                color: #000000;
                margin-bottom: 0;
                font-weight: 500;
              }
              p {
                font-size: 12px;
                color: #808080;
                margin-bottom: 0;
              }
            }
          }
          .prod_price {
            h4 {
              font-weight: 500;
              font-size: 14px;
              margin-bottom: 0;
            }
          }
        }
        .price_div {
          display: flex;
          justify-content: space-between;
          p {
            font-size: 16px;
            color: rgba(102, 102, 102);
            font-weight: 500;
          }

          .delete_icon {
            img {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .element-with-scroll::-webkit-scrollbar {
    display: none;
  }

  .total_price_div {
    padding: 0px 16px;
    display: flex;
    justify-content: space-between;

    p {
      font-size: 18px;
      color: #666666;
    }

    h4 {
      font-weight: 500;
      font-size: 18px;
      color: #000000;
    }
  }
  .but_div {
    padding: 16px;
    margin-bottom: 20px;
    button {
      color: rgba(255, 255, 255);
      font-weight: 600;
      font-size: 1rem;
      text-align: center;
      padding: 1rem 2rem;
      background-color: #000000;
      border: transparent;
      border-radius: 30px;
      width: 100%;
    }
  }

  @media (max-width: 567px) {
    padding: 10px 0px;
    .main_wrapper {
      gap: 0px;
      display: unset;
    }

    .main_wrapper .image_div {
      /*width: 100%;*/

      height: unset;
      padding: 5px;
      margin: 10px;
      width: 93%;

      img {
        width: 90vw;
        border-radius: 10px;
      }
    }
    .main_wrapper .des_div .prod_spec {
      width: 90vw;
      .carattt {
        width: 100%;
        gap: 10px;
        display: flex;
        flex-wrap: nowrap;
        overflow-x: scroll;
        padding-bottom: 10px;
      }
    }
    .main_wrapper .ring_size select {
      width: 100%;
    }
    .main_wrapper .des_div {
      width: 100%;
      margin-top: 20px;
      padding: 5px;
    }
    .main_wrapper .setting_detail .diamond_info .setting_div {
      flex: 1;
    }
    .main_wrapper .des_div .prod_spec .spec {
      padding: 0px 14px;
    }
    .main_wrapper .des_div .prod_spec {
      gap: 20px;
    }
    .main_wrapper .des_div .title h2,
    .main_wrapper .des_div .title h4 {
      font-size: 18px;
    }

    .main_wrapper .setting_main_div .subfirst_detail .profile_div .para {
      margin-bottom: 15px;
    }
    .main_wrapper .setting_main_div .subfirst_detail {
      width: 100%;
    }
    .main_wrapper .setting_main_div .subsecond_detail {
      width: 100%;
    }
    .main_wrapper .setting_detail {
      width: 100%;
    }
    .main_wrapper .setting_Stone .sub_stone .head_cont {
      margin-top: 25px;
    }
    .siz_div {
      width: 100%;
    }

    .main_wrapper .policy .policy_type p {
      font-size: 11px;
    }

    .main_wrapper .des_div .ring_types > div .btn_shapes p {
      line-height: 17px;
    }
    .main_wrapper .des_div .ring_types > div .btn_shapes {
      justify-content: unset;
    }
  }

  /* @media (min-width: 430px) and (max-width: 932px) {
    display:unset;
  } */

  @media (min-width: 567px) and (max-width: 992px) {
    .main_wrapper {
      gap: 0px;
    }

    .main_wrapper .image_div {
      width: 100%;
      height: unset;
      margin: 20px;
      flex: unset;
    }
    .main_wrapper .des_div {
      width: 100%;
      margin-top: 20px;
      padding: 5px;
    }
    .main_wrapper .des_div h2 {
      font-size: 18px;
    }
    .main_wrapper .setting_detail .diamond_info .setting_div {
      flex: 1;
    }
  }

  @media (min-width: 992px) and (max-width: 1026px) {
    .main_wrapper .des_div {
      width: 46%;
      overflow: auto;
      position: relative;
      padding: 10px 0px;
    }
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
  }

  img {
    width: 100%;
    height: 100%;

    @media (max-width: 768px) {
      height: 100%;
    }
  }
`;
